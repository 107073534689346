import React, {
  MouseEvent,
  ReactElement,
  useMemo,
  useState,
} from 'react';
import {
  useAuthProvider,
  useCreatePath,
  useLogin,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import classNames from 'classnames';
import { useTranslate } from 'ra-core';
import { ResourceName } from 'dd-cms-client/app/resources';
import { UserRole } from 'dd-cms-client/auth/utils/userRole';
import './LoginPage.scss';

const LoginPage = (): ReactElement => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const buttonSubmitClassName = classNames(
    'LoginPage-SubmitButton',
    {
      'LoginPage-SubmitButton--Loading': loading,
    },
  );

  const text = useMemo(
    () => ({
      email: t('E-mail'),
      login: t('CMS Login'),
    }),
    [t],
  );

  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();

  const authProvider = useAuthProvider();
  const createPath = useCreatePath();
  const redirect = useRedirect();

  const handleSubmit = (e: MouseEvent<HTMLFormElement>) => { //Logic from RA (node_modules/ra-ui-materialui/src/auth/LoginForm.tsx)
    e.preventDefault();
    setLoading(true);
    login({ password, username: email })
      .then(() => {
        setLoading(false);
        // @ts-ignore
        return authProvider.getPermissions()
          .then(permission => {
            if (permission?.userRole === UserRole.MODERATOR) {
              return redirect(createPath({ resource: ResourceName.BAN, type: 'list' }));
            }
            return;
          })
          .catch(permissionError => {
            notify(permissionError);
          });
      })
      .catch(error => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
          {
            messageArgs: {
              _:
                typeof error === 'string'
                  ? error
                  : error && error.message
                    ? error.message
                    : undefined,
            },
            type: 'warning',
          },
        );
      });
  };

  return (
    <form
      className="LoginPage-Form"
      onSubmit={handleSubmit}
    >
      <h1 className='LoginPage-Header'>
        {text.login}
      </h1>
      <TextField
        className="LoginPage-Input"
        inputProps={{
          pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
        }}
        label={text.email}
        onChange={e => setEmail(e.target.value)}
        type="text"
        required
        variant="outlined"
      />
      <TextField
        className="LoginPage-Input"
        label={translate('ra.auth.password')}
        onChange={e => setPassword(e.target.value)}
        type="password"
        required
        variant="outlined"
      />
      <button
        className={buttonSubmitClassName}
        disabled={loading}
      >
        {translate('ra.auth.sign_in')}
      </button>
    </form>
  );
};

export {
  LoginPage,
};
