import React, { ReactElement, useCallback } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  RaRecord,
  ReferenceArrayInput,
  ReferenceInput,
  useRecordContext,
} from 'react-admin';
import classNames from 'classnames';
import { ResourceName } from 'dd-cms-client/app/resources';
import { Component, Props } from './types';

const DealSelector: Component = ({
  isDisabled,
  isMultiple = false,
  source,
  ...restInputProps
}: Props): ReactElement => {
  const InputComponents = isMultiple ? {
    Autocomplete: AutocompleteArrayInput,
    Reference: ReferenceArrayInput,
  } : {
    Autocomplete: AutocompleteInput,
    Reference: ReferenceInput,
  };

  const disabledClassName = classNames(
    {
      'Field--Disabled': isDisabled,
    },
  );

  const inputText = useCallback(
    (record: RaRecord) => `${record.fields.title} (${record.fields.navisionId})`,
    [],
  );

  const OptionRenderer = () => {
    const record = useRecordContext();

    return (
      <div>
        {inputText(record)}
      </div>
    );
  };


  const matchSuggestion = useCallback(
    (filter: string, record: RaRecord) => (
      record.fields.title.toLowerCase().includes(filter.toLowerCase())
    ),
    [],
  );

  return (
    <InputComponents.Reference
      sort={{ field: 'endDate', order: 'DESC' }}
      perPage={100}
      reference={ResourceName.DEAL}
      source={source}
    >
      {/* @ts-ignore */}
      <InputComponents.Autocomplete
        {...restInputProps}
        className={disabledClassName}
        filterToQuery={
          (searchText: string) => (
            {
              'fields.title': searchText,
            }
          )
        }
        inputText={inputText}
        matchSuggestion={matchSuggestion}
        optionText={<OptionRenderer />}
        optionValue="id"
      />
    </InputComponents.Reference>
  );
};

export {
  DealSelector,
};
